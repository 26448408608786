
import { defineComponent, onMounted, ref } from "vue";
import date from 'quasar/src/utils/date.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QForm from 'quasar/src/components/form/QForm.js';import QSelect from 'quasar/src/components/select/QSelect.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import store from "@/store";
import CursoService from "@/services/admin/CursoService";
import DivisaoService from "@/services/auth/DivisaoService";
import Curso from "@/interfaces/Curso";
import Turma from "@/interfaces/Turma";
import TurmaService from "@/services/admin/TurmaService";
import Frequencia from "@/interfaces/Frequencia";
import BaseService from "@/services/admin/BaseService";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const filtro = ref<string>("");
    const cursoSelected = ref<string>("");
    const turmaSelected = ref<any>();
    const filtroTipo = ref<string>("");
    const filtroDisciplina = ref<string>("");
    const cursos = ref<Curso[]>([]);
    const frequencias = ref<Frequencia[]>([]);
    const turmas = ref<Turma[] | null>([]);
    const data = ref<any>();

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const getCursos = (): void => {
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
            if (!cursos.value.length) {
              showNotify({
                type: "warning",
                message: "Não há cursos para essa divisão",
              });
            }
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          });
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          });
      }
    };

    const getTurmas = (cursoId: string): void => {
      turmaSelected.value = null;
      TurmaService.list()
        .then((res) => {
          turmas.value = res.data.filter((turma: Turma) => {
            return turma.plano.cursos.filter((curso: Curso) => curso.id == cursoId).length > 0;
          })
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        });
    };

    const pesquisar = (): void => {
      BaseService.store("frequencias", {
        turma_id: turmaSelected.value.id,
        data: data.value,
      })
        .then((res) => {
          frequencias.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        });
    }

    const salvarFrequencia = (): void => {
      BaseService.put("frequencias", {
        turma_id: turmaSelected.value.id,
        data: data.value,
        frequencia: frequencias.value
      })
        .then((res) => {
          frequencias.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        });
    }

    onMounted(() => {
      getCursos();
      const timeStamp = Date.now()
      data.value = date.formatDate(timeStamp, 'YYYY-MM-DD');
    });

    return {
      filtro,
      filtroTipo,
      filtroDisciplina,
      store,
      cursos,
      cursoSelected,
      turmas,
      getTurmas,
      turmaSelected,
      pesquisar,
      salvarFrequencia,
      data,
      frequencias,
    };
  },
});
